import { ChipTypeMap, Autocomplete as MuiAutocomplete, AutocompleteProps as MuiAutocompleteProps } from '@mui/material';

export type AutocompleteProps<
    Value,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
> = Pick<
    MuiAutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    | 'disabled'
    | 'getOptionLabel'
    | 'inputValue'
    | 'ListboxProps'
    | 'loading'
    | 'loadingText'
    | 'onBlur'
    | 'onChange'
    | 'onInputChange'
    | 'open'
    | 'openOnFocus'
    | 'options'
    | 'renderInput'
    | 'renderOption'
    | 'sx'
    | 'value'
>;

export function Autocomplete<
    Value,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
    ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>(props: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>): JSX.Element {
    return <MuiAutocomplete {...props} />;
}

Autocomplete.displayName = 'Autocomplete';
