import { useDebouncedValue } from './useDebouncedValue';

type UseDebouncedValueWithLoadingReturns<T> = {
    debouncedValue: T;
    isDebouncing: boolean;
};

export function useDebouncedValueWithLoading<T>(value: T, intervalMs = 500): UseDebouncedValueWithLoadingReturns<T> {
    const debouncedValue = useDebouncedValue(value, intervalMs);

    const isDebouncing = value !== debouncedValue;

    return { debouncedValue, isDebouncing };
}
