import { extendSx } from '@deltasierra/react/theme';
import { ToggleButton as MuiToggleButton, ToggleButtonProps as MuiToggleButtonProps } from '@mui/material';

export type ToggleButtonProps = Pick<
    MuiToggleButtonProps,
    | 'aria-label'
    | 'children'
    | 'color'
    | 'disabled'
    | 'onChange'
    | 'onClickCapture'
    | 'ref'
    | 'selected'
    | 'size'
    | 'sx'
    | 'value'
>;

export const ToggleButton = ({ sx, ...props }: ToggleButtonProps): JSX.Element => (
    <MuiToggleButton
        {...props}
        sx={extendSx(
            theme => ({
                '&.MuiButtonBase-root': {
                    border: theme.mixins.borders.solidInput,
                },
                '&.MuiToggleButtonGroup-grouped + .MuiToggleButtonGroup-grouped': {
                    borderLeft: 'transparent',
                },
            }),
            sx,
        )}
    />
);

ToggleButton.displayName = 'ToggleButton';
