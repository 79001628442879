import { useEffect, useRef } from 'react';

export type UseResizeObserverHandler<T extends HTMLElement = HTMLElement> = (element: T) => void;

export type UseResizeObserverOptions<T extends HTMLElement = HTMLElement> = {
    element: T | null;
    onResize: UseResizeObserverHandler<T>;
};

export function useResizeObserver<T extends HTMLElement = HTMLElement>({
    element,
    onResize,
}: UseResizeObserverOptions<T>): void {
    const resizeObserverRef = useRef<ResizeObserver>();
    const onResizeRef = useRef<UseResizeObserverHandler<T>>(onResize);

    // This makes sure the resize observer always exists
    if (!resizeObserverRef.current) {
        resizeObserverRef.current = new window.ResizeObserver(entries => {
            for (const entry of entries) {
                onResizeRef.current(entry.target as T);
            }
        });
    }

    // Updates the resize ref
    useEffect(() => {
        onResizeRef.current = onResize;
    }, [onResize]);

    // If the element changes then we
    useEffect(() => {
        if (!element) {
            return;
        }
        resizeObserverRef.current!.observe(element);
        // eslint-disable-next-line consistent-return
        return () => {
            resizeObserverRef.current!.unobserve(element);
        };
    }, [element]);
}
