// TODO: Honestly this import needs to be fixed, we should NOT have a dependency like this here
// I have temporarily fixed it from importing frontend/graphql which is even worse
import { TemplateMergeFieldCategory } from '@deltasierra/features/merge-fields/core';

export const mergeTags: Array<
    { tag: string, categories: TemplateMergeFieldCategory[] }
> = [
    { categories: [TemplateMergeFieldCategory.CLUBREADY], tag: 'location_address_city' },
    { categories: [TemplateMergeFieldCategory.CLUBREADY], tag: 'location_address_street' },
    { categories: [TemplateMergeFieldCategory.CLUBREADY], tag: 'location_email' },
    { categories: [TemplateMergeFieldCategory.CLUBREADY], tag: 'location_name' },
    { categories: [TemplateMergeFieldCategory.CLUBREADY], tag: 'location_phone_number' },
    { categories: [TemplateMergeFieldCategory.CLUBREADY], tag: 'location_postal_code' },
    { categories: [TemplateMergeFieldCategory.CLUBREADY], tag: 'location_state' },
    { categories: [TemplateMergeFieldCategory.CLUBREADY], tag: 'to_first_name' },
    { categories: [TemplateMergeFieldCategory.CLUBREADY], tag: 'to_last_name' },
    { categories: [TemplateMergeFieldCategory.OTHER], tag: 'current_year' },
];

export const subjectMergeTags: Array<
    { tag: string, categories: TemplateMergeFieldCategory[] }
> = [
    { categories: [TemplateMergeFieldCategory.CLUBREADY], tag: 'location_name' },
    { categories: [TemplateMergeFieldCategory.CLUBREADY], tag: 'to_first_name' },
    { categories: [TemplateMergeFieldCategory.CLUBREADY], tag: 'to_last_name' },
    { categories: [TemplateMergeFieldCategory.OTHER], tag: 'current_year' },
];