import { ChangeEventHandler, useRef } from 'react';
import { Button, ButtonProps } from './Button';
import { EMAIL_ALLOWED_MIME_TYPES } from './constants';

export type ButtonFileInputContainerProps = Omit<ButtonProps, 'onClick'> & {
    inputType?: 'file' | 'image';
    onChangeFile: ChangeEventHandler<HTMLInputElement>;
};

export const ButtonFileInputContainer = ({
    inputType,
    onChangeFile,
    ...props
}: ButtonFileInputContainerProps): JSX.Element => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const onClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <>
            <input
                accept={inputType === 'image' ? EMAIL_ALLOWED_MIME_TYPES.join(', ') : undefined}
                ref={fileInputRef}
                style={{ display: 'none' }}
                type="file"
                onChange={onChangeFile}
            />
            <Button {...props} onClick={onClick} />
        </>
    );
};

ButtonFileInputContainer.displayName = 'ButtonFileInputContainer';
