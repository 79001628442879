import { useRef, RefObject, useEffect } from 'react';

export type UseResizeObserverHandler<T extends HTMLElement> = (element: T) => void;

export type UseResizeObserverOptions<T extends HTMLElement> = {
    ref: RefObject<T>;
    onResize: UseResizeObserverHandler<T>;
};
export function useFluidGridResizeObserver<T extends HTMLElement>({
    onResize,
    ref,
}: UseResizeObserverOptions<T>): void {
    const resizeObserverRef = useRef<ResizeObserver>();
    const onResizeRef = useRef<UseResizeObserverHandler<T>>(onResize);

    useEffect(() => {
        if (!ref.current) {
            return undefined;
        }

        resizeObserverRef.current = new window.ResizeObserver(entries => {
            for (const entry of entries) {
                onResizeRef.current(entry.target as T);
            }
        });
        const resizeObserver = resizeObserverRef.current;
        const element = ref.current;
        resizeObserver.observe(element);
        return () => {
            resizeObserver.unobserve(element);
        };
    }, [ref]);
}
