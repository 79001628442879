import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { Box } from '@mui/material';
import { useMemo } from 'react';
import { ChipExtended } from './ChipExtended';
import { AutocompleteWithGroupsGroupData, AutocompleteWithGroupsOption } from './types';

type GroupChipValue = {
    type: 'group';
    group: string;
    groupId: string;
    selectedSize?: number;
    selectedTitles: string[];
    totalSize: number;
};

type SingleChipValue = {
    id: string;
    title: string;
    type: 'single';
};

export type ChipValue = GroupChipValue | SingleChipValue;

export type AutocompleteWithGroupsChipProps = {
    groupData?: Map<string, AutocompleteWithGroupsGroupData>;
    onDelete?: (chip: ChipValue) => void;
    value: AutocompleteWithGroupsOption[];
};

export function AutocompleteWithGroupsChips({
    groupData,
    onDelete,
    value,
}: AutocompleteWithGroupsChipProps): JSX.Element {
    const chipValues = useMemo(
        (): ChipValue[] => [
            ...[
                ...value
                    .filter((val): val is typeof val & { groupName: string } => !!val.groupName)
                    .reduce(
                        (acc, opt) => {
                            const curr = acc.get(opt.groupName);
                            acc.set(opt.groupName, {
                                count: (curr?.count ?? 0) + 1,
                                groupName: opt.groupName,
                                groupSize: 0,
                                selectedTitles: [...curr?.selectedTitles ?? [], opt.title],
                            });
                            return acc;
                        },
                        new Map<
                            string,
                            {
                                count: number;
                                groupName: string;
                                groupSize: number;
                                selectedTitles: string[];
                            }
                        >(),
                    )
                    .values(),
            ]
                .map((groupItem): GroupChipValue | null => {
                    const parent = groupData && groupData.get(groupItem.groupName);
                    if (!parent) {
                        return null;
                    }
                    return {
                        group: groupItem.groupName,
                        groupId: parent.groupId,
                        selectedSize: groupItem.count === parent.totalItems ? undefined : groupItem.count,
                        selectedTitles: groupItem.selectedTitles,
                        totalSize: groupItem.groupSize,
                        type: 'group',
                    };
                })
                .filter(isNotNullOrUndefined),
            ...value
                .filter(val => !val.groupName)
                .map(
                    (opt): SingleChipValue => ({
                        id: opt.id,
                        title: opt.title,
                        type: 'single',
                    }),
                ),
        ],
        [groupData, value],
    );

    return (
        <>
            {chipValues.map(chipVal => (
                <Box
                    key={chipVal.type === 'group' ? chipVal.group : chipVal.title}
                    sx={{
                        margin: 0.3,
                    }}
                >
                    <ChipExtended
                        count={chipVal.type === 'group' ? chipVal.selectedSize : undefined}
                        label={chipVal.type === 'group' ? chipVal.group : chipVal.title}
                        tooltip={chipVal.type === 'group' ? chipVal.selectedTitles.join(', ') : undefined}
                        variant="filled"
                        onDelete={onDelete && (() => onDelete(chipVal))}
                    />
                </Box>
            ))}
        </>
    );
}
AutocompleteWithGroupsChips.displayName = 'AutocompleteWithGroupsChips';
