import { extendSx } from '@deltasierra/react/theme';
import { Box, Button, Modal as MuiModal, ModalProps as MuiModalProps, Stack, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { ReactNode, KeyboardEvent } from 'react';

export type ModalProps = Pick<MuiModalProps, 'open'> & {
    buttonCancelText?: string;
    buttonDeleteText?: string;
    buttonNextText?: string;
    buttonsSx?: SxProps<Theme>;
    children: ReactNode | ReactNode[];
    nextOnEnter?: boolean;
    isDisabledCancel?: boolean;
    isDisabledDelete?: boolean;
    isDisabledNext?: boolean;
    isWithParentPadding?: boolean;
    onCancel?: () => void;
    onClose?: () => void;
    onDelete?: () => void;
    onNext?: () => void;
    sx?: SxProps<Theme>;
    title?: ReactNode | ReactNode[] | string;
};

export const Modal = ({
    buttonCancelText,
    buttonDeleteText,
    buttonNextText,
    buttonsSx,
    children,
    isDisabledCancel,
    isDisabledDelete,
    isDisabledNext,
    isWithParentPadding = true,
    nextOnEnter,
    onCancel,
    onClose,
    onDelete,
    onNext,
    open,
    sx,
    title,
}: ModalProps): JSX.Element => {
    const handleNextOnEnter = (event: KeyboardEvent<HTMLDivElement>) => {
        if (open && nextOnEnter && event.code === 'Enter') {
            event.preventDefault();
            onNext?.();
        }
    };

    return (
        <MuiModal disableRestoreFocus open={open} onClose={onClose} onKeyUp={handleNextOnEnter}>
            <Box
                sx={extendSx(
                    {
                        backgroundColor: 'white',
                        borderRadius: 3,
                        left: '50%',
                        minWidth: 440,
                        outline: 'none',
                        padding: isWithParentPadding ? 3 : 0,
                        position: 'absolute',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                    },
                    sx,
                )}
            >
                <Stack spacing={2}>
                    {title && (
                        <Typography
                            sx={{
                                '> svg': {
                                    height: '1em',
                                    verticalAlign: 'middle',
                                    width: '1em',
                                },
                                padding: isWithParentPadding ? '' : '1.5rem 1.5rem 0 1.5rem',
                            }}
                            variant="h2"
                        >
                            {title}
                        </Typography>
                    )}
                    {children}
                    {(onClose || onCancel || onNext || onDelete) && (
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={extendSx({
                                justifyContent: 'flex-end',
                                padding: isWithParentPadding ? '' : '0 1.5rem 1.5rem 1.5rem',
                            }, buttonsSx)}
                        >
                            {(onCancel ?? onClose) && buttonCancelText && (
                                <Button
                                    disabled={isDisabledCancel}
                                    size="large"
                                    variant="outlined"
                                    onClick={onCancel ?? onClose}
                                >
                                    {buttonCancelText}
                                </Button>
                            )}
                            {onNext && buttonNextText && (
                                <Button disabled={isDisabledNext} size="large" variant="contained" onClick={onNext}>
                                    {buttonNextText}
                                </Button>
                            )}
                            {onDelete && (
                                <Button
                                    color="error"
                                    disabled={isDisabledDelete}
                                    size="large"
                                    variant="contained"
                                    onClick={onDelete}
                                >
                                    {buttonDeleteText}
                                </Button>
                            )}
                        </Stack>
                    )}
                </Stack>
            </Box>
        </MuiModal>
    );
};

Modal.displayName = 'Modal';
