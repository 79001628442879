import { RefObject, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useFluidGridResizeObserver } from './useFluidGridResizeObserver';

export const useFluidElementWidth = <T extends HTMLElement>(): { ref: RefObject<T>; rowWidth: number } => {
    const ref = useRef<T>(null);
    const [rowWidth, setRowWidth] = useState(0);

    const onResize = useCallback(() => {
        if (ref.current !== null) {
            setRowWidth(ref.current.offsetWidth);
        }
    }, []);

    useFluidGridResizeObserver<T>({
        onResize,
        ref,
    });

    useLayoutEffect(() => {
        window.addEventListener('resize', onResize);
        onResize();
        return () => window.removeEventListener('resize', onResize);
    }, [onResize]);

    return { ref, rowWidth };
};
