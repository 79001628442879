import { DependencyList, useEffect } from 'react';

/**
 * A helper function for debouncing an effect.
 *
 * @param effect - The effect
 * @param deps - The dependency list
 * @param timeoutMs - The timeout for the debounce
 */
export function useDebouncedEffect(effect: () => void, deps: DependencyList, timeoutMs: number): void {
    useEffect(() => {
        const timeout = setTimeout(effect, timeoutMs);
        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeoutMs, ...deps]);
}
