'use client';

import { useSuspenseQuery } from '@apollo/client';
import {
    GET_ALL_PLATFORMS_AND_LOCATIONS,
    getAllPlatformsAndLocationsQuery,
    getAllPlatformsAndLocationsQueryVariables,
} from '@deltasierra/frontend/graphql';
import { isNotNullOrUndefined } from '@deltasierra/type-utilities';
import { useMemo } from 'react';
import { AvailableIntegration } from './types';

export function useAvailableIntegrations(locationId: string): {
    availableIntegrations: AvailableIntegration[];
} {
    const {
        data: { location: locationData, platforms },
    } = useSuspenseQuery<getAllPlatformsAndLocationsQuery, getAllPlatformsAndLocationsQueryVariables>(
        GET_ALL_PLATFORMS_AND_LOCATIONS,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                id: locationId,
            },
        },
    );

    const availableIntegrations = useMemo((): AvailableIntegration[] => {
        // We need to compile a list of platforms that are ready to use first
        const connectedPlatformNames = (locationData?.integrationConnection.edges ?? [])
            .map(edge => {
                if (!edge.node.isReady) {
                    return null;
                }
                return edge.node.name;
            })
            .filter(isNotNullOrUndefined);
        return platforms.edges
            .map(({ node }) => {
                // These are the only available platforms for email
                switch (node.name) {
                    case 'campaignMonitor':
                    case 'clubReady':
                    case 'glofox':
                    case 'zenoti':
                        break;
                    default:
                        return null;
                }
                // We use the connected platforms list before to filter out ones that are not ready
                if (!connectedPlatformNames.includes(node.name)) {
                    return null;
                }
                // And finally map it to the platform option
                return {
                    displayName: node.displayName,
                    id: node.id,
                    name: node.name,
                };
            })
            .filter(isNotNullOrUndefined);
    }, [locationData?.integrationConnection.edges, platforms.edges]);

    return {
        availableIntegrations,
    };
}
