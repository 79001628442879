import { useEffect, useState } from 'react';

export function useDebouncedValue<T>(value: T, intervalMs = 500): T {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedValue(value);
        }, intervalMs);
        return () => {
            clearTimeout(timeout);
        };
    }, [intervalMs, value]);

    return debouncedValue;
}
