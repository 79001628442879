/**
 * This is a successful result type
 */
export interface SuccessResult<TSuccess> {
    success: true;
    value: TSuccess;
}

/**
 * This is a failure result type.
 * It's failure type should default to an error because that is what we will mostly be returning.
 */
export interface FailureResult<TFailure = Error> {
    success: false;
    failure: TFailure;
}

/**
 * The Failure type defaults to Error because that is the type we want to return most of the time.
 * If we want a custom error type that is fine too.
 */
export type Result<TSuccess, TFailure = Error> = FailureResult<TFailure> | SuccessResult<TSuccess>;

/**
 * Return a success result.
 *
 * @returns A success result
 */
export function successResult(): SuccessResult<void>;
/**
 * Return a success result.
 *
 * @param value - The success value
 * @returns A success result
 */
export function successResult<TSuccess>(value: TSuccess): SuccessResult<TSuccess>;
export function successResult<TSuccess>(value?: TSuccess): SuccessResult<TSuccess> {
    return {
        success: true,
        value: value as TSuccess,
    };
}

/**
 * Return a failure result.
 *
 * @param failure - The failure
 * @returns A failure result
 */
export function failureResult<TFailure>(failure: TFailure): FailureResult<TFailure> {
    return {
        failure,
        success: false,
    };
}

/**
 * Check if the result is a success.
 *
 * @param result - The result that is possibly a success
 * @returns true if the result is a success, otherwise false
 */
export function isSuccessResult<TSuccess, ResultType extends Result<TSuccess, any>>(
    result: ResultType,
): result is ResultType & { success: true } {
    return result.success;
}

/**
 * Check if the result is a failure.
 *
 * @param result - The result that is possibly a failure
 * @returns true if the result is a failure, otherwise false
 */
export function isFailureResult<TFailure, ResultType extends Result<any, TFailure>>(
    result: ResultType,
): result is ResultType & { success: false } {
    return !result.success;
}

/**
 * Unwraps the result or throws the results failure.
 * We force the TFailure type to extend an Error because we shouldn't be able
 * to throw anything but errors. (Technically we can but we don't want to.)
 *
 * @param result - The result
 * @returns The result (Or throws the failure)
 */
export function unwrapOrThrowResult<TSuccess, TFailure extends Error>(result: Result<TSuccess, TFailure>): TSuccess {
    if (result.success) {
        return result.value;
    } else {
        throw result.failure;
    }
}
