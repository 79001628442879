import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';

export type TooltipProps = Pick<
    MuiTooltipProps,
    'children' | 'enterDelay' | 'followCursor' | 'open' | 'placement' | 'title'
>;

export const Tooltip = (props: TooltipProps): JSX.Element => <MuiTooltip {...props} />;

Tooltip.displayName = 'Tooltip';
