import { graphql } from '../../__graphqlTypes';

export const GET_LOCATION_CLIENT = graphql(`
    query GetLocationClient( $locationId: ID!) {
        location(id: $locationId) {
            id
            client {
                id
                showGroupsOnDashboard
            }
            features {
                ...LocationFeaturesFragment
            }
        }
    }
`);
